import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "@mui/material";
import Page from "../components/Page";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <Page title="Privacy policy" description="Privacy policy">
      <Container>
        <section className="section" id="privacy-policy">
          <h3>{t("legal-section.privacy-policy-page.title")}</h3>

          <p>{t("legal-section.privacy-policy-page.introduction")}</p>

          <h6>{t("legal-section.privacy-policy-page.who-title")}</h6>

          <p>{t("legal-section.privacy-policy-page.who-line1")}</p>
          <p>{t("legal-section.privacy-policy-page.who-line2")}</p>

          <h6>{t("legal-section.privacy-policy-page.what-data-title")}</h6>

          <p>{t("legal-section.privacy-policy-page.what-data-line1")}</p>
          <p>{t("legal-section.privacy-policy-page.what-data-line2")}</p>

          <h6>{t("legal-section.privacy-policy-page.what-process-title")}</h6>

          <p>{t("legal-section.privacy-policy-page.what-process-line1")}</p>
          <ul>
            <li>{t("legal-section.privacy-policy-page.what-process-line2")}</li>
            <li>{t("legal-section.privacy-policy-page.what-process-line3")}</li>
            <li>{t("legal-section.privacy-policy-page.what-process-line4")}</li>
            <li>{t("legal-section.privacy-policy-page.what-process-line5")}</li>
            <li>{t("legal-section.privacy-policy-page.what-process-line6")}</li>
            <li>{t("legal-section.privacy-policy-page.what-process-line7")}</li>
            <li>{t("legal-section.privacy-policy-page.what-process-line8")}</li>
          </ul>

          <h6>{t("legal-section.privacy-policy-page.how-long-title")}</h6>

          <p>{t("legal-section.privacy-policy-page.how-long-line1")}</p>
          <ul>
            <li>{t("legal-section.privacy-policy-page.how-long-line2")}</li>
            <li>{t("legal-section.privacy-policy-page.how-long-line3")}</li>
            <li>{t("legal-section.privacy-policy-page.how-long-line4")}</li>
            <li>{t("legal-section.privacy-policy-page.how-long-line5")}</li>
          </ul>
          <p>{t("legal-section.privacy-policy-page.how-long-line6")}</p>

          <h6>{t("legal-section.privacy-policy-page.data-security-title")}</h6>

          <p>{t("legal-section.privacy-policy-page.data-security-line1")}</p>
          <p>{t("legal-section.privacy-policy-page.data-security-line2")}</p>

          <h6>{t("legal-section.privacy-policy-page.what-is-title")}</h6>

          <p>{t("legal-section.privacy-policy-page.what-is-line1")}</p>
          <ul>
            <li>{t("legal-section.privacy-policy-page.what-is-line2")}</li>
            <li>{t("legal-section.privacy-policy-page.what-is-line3")}</li>
            <li>{t("legal-section.privacy-policy-page.what-is-line4")}</li>
            <li>{t("legal-section.privacy-policy-page.what-is-line5")}</li>
          </ul>
          <p>{t("legal-section.privacy-policy-page.what-is-line6")}</p>
          <p>{t("legal-section.privacy-policy-page.what-is-line7")}</p>
          <p>{t("legal-section.privacy-policy-page.what-is-line8")}</p>
          <p>{t("legal-section.privacy-policy-page.what-is-line9")}</p>
          <p>{t("legal-section.privacy-policy-page.what-is-line10")}</p>
          <p>{t("legal-section.privacy-policy-page.what-is-line11")}</p>

          <h6>{t("legal-section.privacy-policy-page.with-which-title")}</h6>

          <p>{t("legal-section.privacy-policy-page.with-which-line1")}</p>
          <ul>
            <li>{t("legal-section.privacy-policy-page.with-which-line2")}</li>
          </ul>
          <p>{t("legal-section.privacy-policy-page.with-which-line3")}</p>

          <h6>{t("legal-section.privacy-policy-page.what-rights-title")}</h6>

          <p>{t("legal-section.privacy-policy-page.what-rights-line1")}</p>
          <p>{t("legal-section.privacy-policy-page.what-rights-line2")}</p>
          <p>{t("legal-section.privacy-policy-page.what-rights-line3")}</p>

          <h6>{t("legal-section.privacy-policy-page.use-of-social-title")}</h6>

          <p>{t("legal-section.privacy-policy-page.use-of-social-line1")}</p>
          <p>{t("legal-section.privacy-policy-page.use-of-social-line2")}</p>
          <p>{t("legal-section.privacy-policy-page.use-of-social-line3")}</p>
          <p>{t("legal-section.privacy-policy-page.use-of-social-line4")}</p>
          <p>{t("legal-section.privacy-policy-page.use-of-social-line5")}</p>
        </section>
      </Container>
    </Page>
  );
};

export default PrivacyPolicy;
